@import "../../../../public/mixins//mixins.scss";

.servicesWrapper {
  max-width: 1440px;
  padding: 35px 60px;
  margin: auto;
  @include flexContainer(row, unset, unset, 40px, 40px, wrap);
  @include mobile() {
    padding: 30px 20px;
    row-gap: 20px;
  }
}
