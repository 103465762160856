@import "../../../../public/mixins/mixins.scss";

.successStoriesWrapper {
  max-width: 1440px;
  margin: auto;
  padding: 50px;
  @include flexContainer(column, unset, center, 40px);
  @include mobile() {
    row-gap: 20px;
    padding: 20px 50px;
  }

  .successStoriesHeading {
    text-align: center;
    margin-bottom: 35px;
    @include font(32px, 300, #4a536a, 1.28px);
    @include mobile() {
      font-size: 20px;
      margin-bottom: 0px;
    }
    .highlighted {
      @include font(32px, 700, #006daf, 1.28px);
      @include mobile() {
        font-size: 20px;
      }
    }
  }
  .viewMoreBtn {
    @include buttonType1(10px 30px);
  }
}
